import React, { useState } from 'react';
import ReactDOM from 'react-dom';

interface ResponseRow {
  datasetid: string;
  query: string;
  count: number;
}

interface TaskParameters {
  domain: string;
  daysAgo: 1;
}

const RollupsSuggestionForm = () => {
  const [responseTableRows, setResponseTableRows] = useState<ResponseRow[]>([]);
  const [rollupTaskParameters, setRollupTaskParameters] = useState<TaskParameters>({
    domain: '',
    daysAgo: 1
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [suggestionsExpected, setSuggestionsExpected] = useState(false);
  const [isLoadingSuggestedRollups, setIsLoadingSuggestedRollups] = useState(false);
  const [getRollupSuggestionsError, setGetRollupSuggestionsError] = useState(null);
  const [selectedRollups, setSelectedRollups] = useState<number[]>([]);
  const [createJobDone, setCreateJobDone] = useState<boolean | null>(null);
  const [isCreatingRollups, setIsCreatingRollups] = useState(false);

  const itemsPerPage = 3;
  const noSuggestions = responseTableRows.length === 0;

  async function pollForResults(taskId: string) {
    let completed = false;
    let result = null;

    while (!completed) {
      const response = await fetch(
        `/internal/domains/${window.location.hostname}/retrieve_dia_task_result?taskId=${taskId}`,
        {
          method: 'GET'
        }
      );
      const data = await response.json();

      if (data.response.status === 'Done') {
        completed = true;
        result = data.response.result;
      } else if (data.response.status === 'Failed') {
        console.log('Last task info', data.response);
        throw new Error('Rollup job failed');
      } else {
        await new Promise((resolve) => setTimeout(resolve, 30 * 1000));
      }
    }
    return result;
  }

  const handleSubmitSuggestRollups = async (e: React.FormEvent) => {
    setIsLoadingSuggestedRollups(true);
    setGetRollupSuggestionsError(null);
    setResponseTableRows([]);
    setCreateJobDone(null);
    setSuggestionsExpected(false);
    e.preventDefault();
    try {
      const body = new FormData();
      body.append(
        'authenticity_token',
        document.querySelector('meta[name="csrf-token"]')!.getAttribute('content')!
      );
      body.append('commit', 'Suggest Rollups');
      Object.keys(rollupTaskParameters).forEach((key) => {
        body.append(key, rollupTaskParameters[key]);
      });
      const startResponse = await fetch(
        `/internal/domains/${window.location.hostname}/suggest_rollups_for_creation`,
        {
          method: 'POST',
          body
        }
      );
      const data: { taskId?: string } = await startResponse.json();
      if (!data.taskId) {
        throw new Error('Problem starting suggest job, no task id returned');
      }

      const result = await pollForResults(data.taskId);

      const suggestedRollups = JSON.parse(result).suggestedRollups;
      setResponseTableRows(suggestedRollups);
      setCurrentPage(1);
      setSuggestionsExpected(true);
    } catch (error) {
      setGetRollupSuggestionsError(error.message);
    } finally {
      setIsLoadingSuggestedRollups(false);
    }
  };

  const handleSubmitApplyRollup = async (applyAllSuggestions: boolean) => {
    setSuggestionsExpected(false);
    setIsCreatingRollups(true);
    const token = document.querySelector('meta[name="csrf-token"]')!.getAttribute('content')!;
    const bodies = [];
    try {
      if (selectedRollups.length !== responseTableRows.length && !applyAllSuggestions) {
        // User has selected a subset of rollups to create, create a request for dataset
        const rollupMap = new Map<string, string[]>();
        for (let i = 0; i < selectedRollups.length; i++) {
          const datasetid = responseTableRows[selectedRollups[i]].datasetid;
          const query = responseTableRows[selectedRollups[i]].query;

          if (!rollupMap[datasetid]) {
            rollupMap[datasetid] = [];
          }

          rollupMap[datasetid].push(query);
        }

        for (const [datasetid, queries] of Object.entries(rollupMap)) {
          // For each dataset, add a request to create the rollups for each dataset
          const body = new FormData();
          body.append('authenticity_token', token);
          body.append('commit', 'Apply Rollups');
          Object.keys(rollupTaskParameters).forEach((key) => {
            body.append(key, rollupTaskParameters[key]);
          });
          queries.forEach((query: string) => {
            body.append('queries[]', query);
          });
          body.append('datasetid', datasetid);
          bodies.push(body);
        }
      } else {
        // If they are applying all rollups, add a single request without queries/dataset
        const body = new FormData();
        body.append('authenticity_token', token);
        body.append('commit', 'Apply Rollups');
        Object.keys(rollupTaskParameters).forEach((key) => {
          body.append(key, rollupTaskParameters[key]);
        });
        bodies.push(body);
      }
      // At this point, all requests are in the bodies list
      // for each one, start the job and poll for results
      for (const body of bodies) {
        const startResponse = await fetch(
          '/internal/domains/${window.location.hostname}/apply_rollup_creation_suggestions',
          {
            method: 'POST',
            body
          }
        );
        const data: { taskId?: string } = await startResponse.json();
        if (!data.taskId) {
          throw new Error('Problem starting apply job, no task id returned');
        }
        const result = await pollForResults(data.taskId);
      }
      // Any failures would've thrown errors by now
      setCreateJobDone(true);
      setIsCreatingRollups(false);
    } catch (error) {
      setCreateJobDone(false);
      console.log(error.message);
    }
  };

  let totalPages = 0;
  let currentItems: ResponseRow[] = [];
  let multiPageTable = true;
  const startIndex = (currentPage - 1) * itemsPerPage;
  if (responseTableRows) {
    totalPages = Math.ceil(Object.keys(responseTableRows)?.length / itemsPerPage);
    if (totalPages === 1) {
      multiPageTable = false;
    }
    currentItems = responseTableRows.slice(startIndex, startIndex + itemsPerPage);
  }

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  function handleFormChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = e.target;
    setRollupTaskParameters({
      ...rollupTaskParameters,
      [name]: value
    });
  }

  const handleCheckingSuggestions = (e: React.FormEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    if (target.checked) {
      setSelectedRollups([...selectedRollups, parseInt(target.value)]);
    } else {
      setSelectedRollups(selectedRollups.filter((rollup) => rollup !== parseInt(target.value)));
    }
  };

  return (
    <div style={{ minHeight: 250 }}>
      {isLoadingSuggestedRollups ? (
        <div>Loading ...</div>
      ) : (
        <form onSubmit={handleSubmitSuggestRollups}>
          <label>
            Domain:
            <input
              type="text"
              name="domain"
              value={rollupTaskParameters.domain}
              onChange={handleFormChange}
            />
          </label>
          <br />
          <label>
            Days Ago:
            <input
              type="number"
              name="daysAgo"
              value={rollupTaskParameters.daysAgo}
              onChange={handleFormChange}
            />
          </label>
          <br />
          <button type="submit">Suggest Rollups</button>
        </form>
      )}
      {getRollupSuggestionsError && <div>The following error occured: {getRollupSuggestionsError}</div>}
      {suggestionsExpected && noSuggestions ? (
        <div
          style={{
            background: '#ffe5e5',
            width: 'max-content',
            padding: '5px 10px',
            borderRadius: '5px'
          }}
        >
          No rollups suggested for this domain during this time period
        </div>
      ) : (
        suggestionsExpected &&
        responseTableRows &&
        createJobDone === null && (
          <div>
            {isCreatingRollups ? (
              <div
                style={{
                  background: '#d4d4f3',
                  width: 'max-content',
                  padding: '5px 10px',
                  borderRadius: '5px'
                }}
              >
                Creating the selected rollups ...
              </div>
            ) : (
              <div>
                <button onClick={() => handleSubmitApplyRollup(false)}>Create Selected Rollups</button>
                <button onClick={() => handleSubmitApplyRollup(true)}>Create All Rollups</button>
              </div>
            )}
            <table>
              <thead>
                <tr>
                  <th>Dataset ID</th>
                  <th>Query</th>
                  <th>Count</th>
                  <th>Select Rollup</th>
                </tr>
              </thead>
              <tbody>
                {currentItems.map((row, index) => (
                  <tr key={(currentPage - 1) * itemsPerPage + index}>
                    <td>{row.datasetid}</td>
                    <td>{row.query}</td>
                    <td>{row.count}</td>
                    <td>
                      <input
                        value={(currentPage - 1) * itemsPerPage + index}
                        type="checkbox"
                        checked={selectedRollups.includes((currentPage - 1) * itemsPerPage + index)}
                        onChange={handleCheckingSuggestions}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
              {multiPageTable && (
                <div>
                  <button onClick={handlePreviousPage} disabled={currentPage === 1}>
                    Previous Page
                  </button>
                  <span>
                    Page {currentPage} of {totalPages}
                  </span>
                  <button onClick={handleNextPage} disabled={currentPage === totalPages}>
                    Next Page
                  </button>
                </div>
              )}
            </table>
          </div>
        )
      )}
      {createJobDone === true && (
        <div
          style={{
            background: '#dbf7db',
            width: 'max-content',
            padding: '5px 10px',
            borderRadius: '5px'
          }}
        >
          The selected rollup(s) have been successfully created
        </div>
      )}
      {createJobDone === false && <div>An issue came up while creating the selected rollup(s)</div>}
    </div>
  );
};

ReactDOM.render(<RollupsSuggestionForm />, document.querySelector('#rollup-creation-app'));
